import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useEffect, useState } from "react";
import CodeIcon from "@mui/icons-material/Code";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import axios from "axios";
import moment from "moment";
import {
	workforceApprovalRole,
	cashCardApprovalRole,
	cashCardFranchiseAssociation,
	franchiseData,
	months,
	workforceFranchiseAssociation,
} from "data/eboPaymentsData";
import dayjs from "dayjs";
import { GlobalState } from "../../../context/GlobalContext";
import FileSaver from "file-saver";
import { isStartDateGraterThanEndDate } from "utils/isStartDateGreaterThanEndDate";
import { modifyExcel } from "utils/excelTemplateEboPayment";
import { MenuItem, Modal, Select } from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import DisplayDatagrid from "./DisplayDatagrid/DisplayDatagrid";
import { DataGrid } from "@mui/x-data-grid";
import { allowAccess } from "utils/userAccessFun";
import { AuthState } from "context/AuthContext";
import { roundDecimalValueUptoTwo, roundDecimalValueUptoTwoDigit } from "utils/roundDecimalValue";
import { CheckCircleOutlineRounded, ErrorOutlineRounded } from "@mui/icons-material";
const colStyle = {
	headerAlign: "center",
	align: "center",
};
const franchiseTableColumns = [
	{
		field: "month",
		headerName: "Month",
		attribute_name: "",
		width: 60,

		...colStyle,
	},
	{
		field: "year",
		headerName: "Year",
		attribute_name: "",
		width: 55,
		...colStyle,
	},
	{
		field: "franchise_name",
		headerName: "Name",
		width: 90,
		...colStyle,
	},
	{
		field: "franchise_key",
		headerName: "Full Name",
		width: 250,
		...colStyle,
	},

	{
		field: "created_user_email",
		headerName: "Created By",
		width: 160,
		...colStyle,
	},

	{
		field: "is_approved",
		headerName: "Approved",
		width: 90,
		...colStyle,
		renderCell: (params) => {
			return (
				<Box
					sx={{
						backgroundColor: params.row.is_approved ? "#4caf50" : grey[500],
						color: "white",
						padding: "5px",
						borderRadius: "5px",
					}}>
					<></>
					{params.row.is_approved ? (
						<Box display="flex" alignItems="center" columnGap={1}>
							<CheckCircleOutlineRounded /> Yes
						</Box>
					) : (
						<Box display="flex" alignItems="center" columnGap={1}>
							<ErrorOutlineRounded />
							No
						</Box>
					)}
				</Box>
			);
		},
	},
	{
		field: "approved_user_email",
		headerName: "Approved By",
		width: 190,
		...colStyle,
	},
];

const cashCardTableColumns = [
	{
		field: "store_display_name",
		headerName: "Store",
		width: 250,
		...colStyle,
	},
	{
		field: "realised_value",
		headerName: "Realised Value",
		width: 120,
		...colStyle,
	},
	{
		field: "received_through_cash",
		headerName: "  Cash",
		width: 130,
		editable: true,
		...colStyle,
	},
	{
		field: "received_through_card",
		headerName: "  Card",
		width: 130,
		editable: true,
		...colStyle,
	},
	{
		field: "received_through_gift_card",
		headerName: "  Gift Card",
		width: 130,
		editable: true,
		...colStyle,
	},
	{
		field: "difference",
		headerName: "Difference",
		width: 130,
		...colStyle,
	},
];
const workForceTableColumns = [
	{
		field: "store_display_name",
		headerName: "Store",
		width: 200,
		...colStyle,
	},
	{
		field: "gross_salary",
		headerName: "Gross Salary",
		width: 120,
		editable: true,
		...colStyle,
	},
	{
		field: "incentive",
		headerName: "Incentive",
		width: 100,
		editable: true,

		...colStyle,
	},
	{
		field: "employer_esi",
		headerName: "Employer ESI",
		width: 118,
		editable: true,

		...colStyle,
	},
	{
		field: "employer_pf",
		headerName: "Employer Pf",
		width: 120,
		editable: true,

		...colStyle,
	},
	{
		field: "total_liability",
		headerName: "Total Manpower Cost",
		width: 160,
		editable: true,
		...colStyle,
	},
];

const CashCardCollection = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const { user } = AuthState();

	const [franchiseLevData, setFranchiseLevData] = useState();
	const [month, setMonth] = useState("select");
	const [year, setYear] = useState("select");
	const [franchiseName, setFranchiseName] = useState("select");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const [stores, setStores] = useState();
	const [updatedStores, setUpdatedStores] = useState([]);
	const [selectedFranchise, setSelectedFranchise] = useState();
	const currentYear = Number(moment().format("YYYY"));

	useEffect(() => {
		const fetch = async () => {
			await axios
				.get("/api/ebo_payments/franchise_cashcard")
				.then((res) => {
					setFranchiseLevData(res.data);
				})
				.catch((error) => {
					setSnackBar({
						...snackBar,
						message: "Error : Unable to load data",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setSnackBarOpen(true);
				});
		};
		fetch();
	}, []);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState("create");
	const modalStyle = {
		width: 706,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: "10px 15px",
		borderRadius: 2,
	};

	const handleFranchiseClick = (row) => {
		setSelectedFranchise(row);
		setStores(row[cashCardFranchiseAssociation]);
		setModalOpen(true);
	};
	const handleCreateClick = async (e, type) => {
		e.preventDefault();
		if (year === "select" || month === "select" || franchiseName === "select") {
			setSnackBar({
				...snackBar,
				message: "Error : Select All the Fields",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
			return;
		}
		setModalType(type);
		const franchise = franchiseData.filter((row) => row.key === franchiseName)[0];
		setLoading(true);
		try {
			await axios.get(`/api/ebo_payments/get_franchise_store_data?franchise=${franchise.name}`).then(async (res) => {
				const storesServerData = res.data;
				console.log(storesServerData);

				const storesData = storesServerData.map((row) => {
					return {
						store_display_name: row.SHORT_CODE,
						franchise_store_name: row.NAME,
						realised_value: roundDecimalValueUptoTwoDigit(row.REALISED_VALUE),
						difference: roundDecimalValueUptoTwoDigit(row.REALISED_VALUE),
					};
				});
				const reqBody = {
					month,
					year,
					franchise_name: franchise.name,
					franchise_key: franchise.key,
					[cashCardFranchiseAssociation]: storesData,
				};
				const createFranchiseData = await axios.post("/api/ebo_payments/franchise_cashcard", reqBody);
				setSelectedFranchise(createFranchiseData.data);
				setFranchiseLevData([createFranchiseData.data, ...franchiseLevData]);
				setStores(createFranchiseData.data[cashCardFranchiseAssociation]);
				setModalOpen(true);
			});
		} catch (error) {
			console.log(error.response.status);
			setSnackBar({
				...snackBar,
				message: error?.response?.status == 403 ? "Record already exists" : "Internal Server Error",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
		}
		setLoading(false);
	};
	const handleRowDataChange = (updatedRow) => {
		let received_through_card = parseFloat(updatedRow.received_through_card);
		let received_through_cash = parseFloat(updatedRow.received_through_cash);
		let received_through_gift_card = parseFloat(updatedRow.received_through_gift_card);
		const difference = +updatedRow.realised_value - (received_through_cash + received_through_card + received_through_gift_card);
		const changedRow = {
			...updatedRow,
			received_through_cash,
			received_through_card,
			received_through_gift_card,
			difference,
		};
		setUpdatedStores([...updatedStores.filter((row) => row.id !== updatedRow.id), changedRow]);
		return changedRow;
	};
	const handleSaveFunction = async (approve) => {
		if (updatedStores.length === 0 && !approve) {
			setSnackBar({
				...snackBar,
				message: "Error : No changes to save",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
			return;
		}
		setLoading(true);
		try {
			await axios
				.post(`/api/ebo_payments/franchise_cashcard_bulk_update?${approve === true && "approve=true"}`, {
					updatedStores,
					approvedFranchiseId: selectedFranchise.id,
				})
				.then((res) => {
					let map = {};
					updatedStores.forEach((row, i) => (map[row.id] = i));

					const newStoreData = stores.map((row) => {
						if (map[row.id] >= 0) return updatedStores[map[row.id]];
						else return row;
					});
					console.log("approve", approve);
					setFranchiseLevData(
						franchiseLevData?.map((row) => {
							if (row.id === selectedFranchise.id) {
								return {
									...selectedFranchise,
									is_approved: approve,
									approved_user_email: approve ? user.email : "",
									[cashCardFranchiseAssociation]: newStoreData,
								};
							} else return row;
						})
					);
					setSnackBar({
						...snackBar,
						message: "Updated changes successful",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					// setSelectedFranchise();
					setStores(res.data);
					setSnackBarOpen(true);
					setModalOpen(false);
					setLoading(false);
				});
		} catch (error) {
			setSnackBar({
				...snackBar,
				message: error?.response?.status == 403 ? "Record already exists" : "Internal Server Error",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
			setLoading(false);
		}
	};
	const cancelSaveFunction = () => {
		setUpdatedStores();
	};
	const handleCancelModalClick = () => {
		setSelectedFranchise();

		if (modalType === "create") {
			setMonth("select");
			setYear("select");
			setFranchiseName("select");
		}
		setModalOpen(false);
	};

	return (
		<Stack spacing={2} component="form" onSubmit={(e) => handleCreateClick(e, "create")}>
			<Typography variant="h6">CASH AND CARD COLLECTION</Typography>
			{/* form */}
			<Box
				display="flex"
				width="690px"
				columnGap={2}
				alignItems="center"
				sx={{ backgroundColor: grey[200], p: 2, borderRadius: 1 }}>
				<Stack spacing={1}>
					<InputLabel>Select Year</InputLabel>
					<Select
						size="small"
						sx={{ width: 130 }}
						value={year}
						onChange={(e) => setYear(e.target.value)}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
						<MenuItem value={"select"}>Select Year</MenuItem>
						{[-2, -1, 0, 1].map((row) => {
							let year = +currentYear + row;
							return (
								<MenuItem key={year} value={year}>
									{year}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select Month</InputLabel>
					<Select
						size="small"
						sx={{ width: 140 }}
						value={month}
						onChange={(e) => setMonth(e.target.value)}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
						<MenuItem value={"select"}> Select Month</MenuItem>
						{months.map((row) => {
							return (
								<MenuItem key={row.name} value={row.key}>
									{row.name}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select Franchise</InputLabel>
					<Select
						size="small"
						sx={{ width: 250 }}
						value={franchiseName}
						onChange={(e) => setFranchiseName(e.target.value)}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
						<MenuItem value={"select"}> Select Franchise</MenuItem>
						{franchiseData.map((row) => {
							return (
								<MenuItem key={row.key} value={row.key}>
									{row.name}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
				<Box>
					<Button
						size="large"
						disabled={loading}
						variant="contained"
						sx={{ width: "120px" }}
						type="submit"
						startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
						{loading ? "Loading..." : "Add"}
					</Button>
				</Box>
			</Box>

			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			<DisplayDatagrid
				columns={franchiseTableColumns}
				rows={franchiseLevData ? franchiseLevData : []}
				onRowClick={handleFranchiseClick}
			/>

			<Modal
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
				open={modalOpen}
				onClose={() => setModalOpen(false)}>
				<Stack sx={{ ...modalStyle, width: 1000 }} spacing={1} alignItems="center">
					<Box display="flex" width="95%" justifyContent="space-between" p={1}>
						<Typography variant="h6" textTransform="uppercase">
							{selectedFranchise?.franchise_name} Stores
						</Typography>
						<Typography variant="h6">
							{months[+selectedFranchise?.month + 1]?.name} {selectedFranchise?.year}
						</Typography>
					</Box>
					<DataGrid
						rows={stores ? stores : []}
						// getRowId={getRowId}
						columns={cashCardTableColumns}
						// showColumnVerticalBorder
						showCellVerticalBorder
						// hideFooter={true}
						sx={{
							minHeight: 200,
							// border: "none",
							width: "95%",
							"& .MuiDataGrid-row": {
								borderLeft: "1px solid lightgray",
								borderRight: "2px solid lightgray",
								cursor: "pointer",
								// background: grey[200],
							},
							"& .MuiDataGrid-root": {
								// borderRight: "1px solid lightgray",
								cursor: "pointer",
								border: "none",
							},
							"& .MuiDataGrid-footerContainer": {
								maxHeight: "30px",
								minHeight: "30px",
								height: "10px",
								padding: "0px",
								// background: "grey",
							},
							"& .MuiDataGrid-cell": {
								padding: 0,
								// borderBottom: "0px",
							},
							// "& .MuiDataGrid-footerCell": {
							// },
						}}
						rowHeight={35}
						processRowUpdate={handleRowDataChange}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 10 },
							},
						}}
						disableRowSelectionOnClick
						pageSizeOptions={[5, 10]}
					/>
					<Box
						sx={{
							alignSelf: "center",
							justifyContent: "center",
							display: "flex",
							width: "100%",
						}}>
						<Box display="flex" columnGap={2}>
							<Button onClick={handleCancelModalClick} variant="outlined" sx={{ width: "100px" }}>
								Cancel
							</Button>
							{selectedFranchise && !selectedFranchise.is_approved === true && (
								<>
									{allowAccess(cashCardApprovalRole, user) && (
										<Button
											disabled={loading}
											onClick={() => handleSaveFunction(true)}
											variant="contained"
											sx={{ minWidth: "120px" }}>
											Save and Approve
										</Button>
									)}
									<Button
										disabled={loading}
										onClick={(e) => handleSaveFunction(false)}
										variant="contained"
										sx={{ minWidth: "120px" }}>
										Save
									</Button>
								</>
							)}
						</Box>
					</Box>
				</Stack>
			</Modal>
		</Stack>
	);
};
const WorkForceData = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const { user } = AuthState();

	const [franchiseLevData, setFranchiseLevData] = useState();
	const [month, setMonth] = useState("select");
	const [year, setYear] = useState("select");
	const [franchiseName, setFranchiseName] = useState("select");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const [stores, setStores] = useState();
	const [updatedStores, setUpdatedStores] = useState([]);
	const [selectedFranchise, setSelectedFranchise] = useState();
	const currentYear = Number(moment().format("YYYY"));

	useEffect(() => {
		const fetch = async () => {
			await axios
				.get("/api/ebo_payments/franchise_manpower")
				.then((res) => {
					setFranchiseLevData(res.data);
				})
				.catch((error) => {
					setSnackBar({
						...snackBar,
						message: "Error : Unable to load data",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setSnackBarOpen(true);
				});
		};
		fetch();
	}, []);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState("create");
	const modalStyle = {
		width: 706,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: "10px 15px",
		borderRadius: 2,
	};

	const handleFranchiseClick = (row) => {
		setSelectedFranchise(row);
		setStores(row[workforceFranchiseAssociation]);
		setModalOpen(true);
	};
	const handleCreateClick = async (e, type) => {
		e.preventDefault();
		if (year === "select" || month === "select" || franchiseName === "select") {
			setSnackBar({
				...snackBar,
				message: "Error : Select All the Fields",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
			return;
		}
		setModalType(type);
		const franchise = franchiseData.filter((row) => row.key === franchiseName)[0];
		setLoading(true);
		try {
			await axios.get(`/api/ebo_payments/get_franchise_store_data?franchise=${franchise.name}`).then(async (res) => {
				const storesServerData = res.data;
				console.log(storesServerData);

				const storesData = storesServerData.map((row) => {
					return {
						store_display_name: row.SHORT_CODE,
						franchise_store_name: row.NAME,
					};
				});
				const reqBody = {
					month,
					year,
					franchise_name: franchise.name,
					franchise_key: franchise.key,
					[workforceFranchiseAssociation]: storesData,
				};
				const createFranchiseData = await axios.post("/api/ebo_payments/franchise_manpower", reqBody);
				setSelectedFranchise(createFranchiseData.data);
				setFranchiseLevData([createFranchiseData.data, ...franchiseLevData]);
				setStores(createFranchiseData.data[workforceFranchiseAssociation]);
				setModalOpen(true);
			});
		} catch (error) {
			setSnackBar({
				...snackBar,
				message: error?.response?.status == 403 ? "Record already exists" : "Internal Server Error",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
		}
		setLoading(false);
	};
	const handleRowDataChange = (updatedRow) => {
		setUpdatedStores([
			...updatedStores.filter((row) => row.id !== updatedRow.id),
			{
				...updatedRow,
				gross_salary: parseFloat(updatedRow.gross_salary),
				incentive: parseFloat(updatedRow.incentive),
				employer_esi: parseFloat(updatedRow.employer_esi),
				employer_pf: parseFloat(updatedRow.employer_pf),
				total_liability: parseFloat(updatedRow.total_liability),
			},
		]);
		return updatedRow;
	};
	const handleSaveFunction = async (approve) => {
		if (updatedStores.length === 0 && !approve) {
			setSnackBar({
				...snackBar,
				message: "Error : No changes to save",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
			return;
		}
		setLoading(true);
		try {
			await axios
				.post(`/api/ebo_payments/franchise_manpower_bulk_update?${approve === true && "approve=true"}`, {
					updatedStores,
					approvedFranchiseId: selectedFranchise.id,
				})
				.then((res) => {
					let map = {};
					updatedStores.forEach((row, i) => (map[row.id] = i));

					const newStoreData = stores.map((row) => {
						if (map[row.id] >= 0) return updatedStores[map[row.id]];
						else return row;
					});
					setFranchiseLevData(
						franchiseLevData?.map((row) => {
							if (row.id === selectedFranchise.id) {
								return {
									...selectedFranchise,
									is_approved: approve,
									approved_user_email: approve ? user.email : "",
									[workforceFranchiseAssociation]: newStoreData,
								};
							} else return row;
						})
					);
					setSnackBar({
						...snackBar,
						message: "Updated changes successful",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setSelectedFranchise();
					setStores();
					setSnackBarOpen(true);
					setModalOpen(false);
					setLoading(false);
				});
		} catch (error) {
			console.log(error.response.status);
			setSnackBar({
				...snackBar,
				message: error?.response?.status == 403 ? "Record already exists" : "Internal Server Error",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
			setLoading(false);
		}
	};
	const cancelSaveFunction = () => {
		setUpdatedStores();
	};
	const handleCancelModalClick = () => {
		setSelectedFranchise();

		if (modalType === "create") {
			setMonth("select");
			setYear("select");
			setFranchiseName("select");
		}
		setModalOpen(false);
	};

	return (
		<Stack spacing={2} component="form" onSubmit={(e) => handleCreateClick(e, "create")}>
			<Typography variant="h6">CASH AND CARD COLLECTION</Typography>
			{/* form */}
			<Box
				display="flex"
				width="690px"
				columnGap={2}
				alignItems="center"
				sx={{ backgroundColor: grey[200], p: 2, borderRadius: 1 }}>
				<Stack spacing={1}>
					<InputLabel>Year</InputLabel>
					<Select
						size="small"
						sx={{ width: 130 }}
						value={year}
						onChange={(e) => setYear(e.target.value)}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
						<MenuItem value={"select"}>Select Year</MenuItem>
						{[-2, -1, 0, 1].map((row) => {
							let year = +currentYear + row;
							return (
								<MenuItem key={year} value={year}>
									{year}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Month</InputLabel>
					<Select
						size="small"
						sx={{ width: 140 }}
						value={month}
						onChange={(e) => setMonth(e.target.value)}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
						<MenuItem value={"select"}> Select Month</MenuItem>
						{months.map((row) => {
							return (
								<MenuItem key={row.name} value={row.key}>
									{row.name}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Franchise</InputLabel>
					<Select
						size="small"
						sx={{ width: 250 }}
						value={franchiseName}
						onChange={(e) => setFranchiseName(e.target.value)}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
						<MenuItem value={"select"}> Select Franchise</MenuItem>
						{franchiseData.map((row) => {
							return (
								<MenuItem key={row.key} value={row.key}>
									{row.name}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
				<Box>
					<Button
						size="large"
						disabled={loading}
						variant="contained"
						sx={{ width: "120px" }}
						type="submit"
						startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
						{loading ? "Loading..." : "Add"}
					</Button>
				</Box>
			</Box>

			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			<DisplayDatagrid
				columns={franchiseTableColumns}
				rows={franchiseLevData ? franchiseLevData : []}
				onRowClick={handleFranchiseClick}
			/>

			<Modal
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
				open={modalOpen}
				onClose={() => setModalOpen(false)}>
				<Stack sx={{ ...modalStyle, width: 1000 }} spacing={1} alignItems="center">
					<Box display="flex" width="95%" justifyContent="space-between" p={1}>
						<Typography variant="h6" textTransform="uppercase">
							{selectedFranchise?.franchise_name} Stores
						</Typography>
						<Typography variant="h6">
							{months[+selectedFranchise?.month + 1]?.name} {selectedFranchise?.year}
						</Typography>
					</Box>
					<DataGrid
						rows={stores ? stores : []}
						// getRowId={getRowId}
						columns={workForceTableColumns}
						// showColumnVerticalBorder
						showCellVerticalBorder
						// hideFooter={true}
						sx={{
							minHeight: 200,
							// border: "none",
							width: "95%",
							"& .MuiDataGrid-row": {
								borderLeft: "1px solid lightgray",
								borderRight: "2px solid lightgray",
								cursor: "pointer",
							},
							"& .MuiDataGrid-root": {
								// borderRight: "1px solid lightgray",
								cursor: "pointer",
								border: "none",
							},
							"& .MuiDataGrid-footerContainer": {
								maxHeight: "30px",
								minHeight: "30px",
								height: "10px",
								padding: "0px",
								// background: "grey",
							},
							"& .MuiDataGrid-cell": {
								padding: 0,
								// borderBottom: "0px",
							},
							// "& .MuiDataGrid-footerCell": {
							// },
						}}
						rowHeight={35}
						processRowUpdate={handleRowDataChange}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 10 },
							},
						}}
						disableRowSelectionOnClick
						pageSizeOptions={[5, 10]}
					/>
					<Box
						sx={{
							alignSelf: "center",
							justifyContent: "center",
							display: "flex",
							width: "100%",
						}}>
						<Box>
							<Button onClick={handleCancelModalClick} variant="outlined" sx={{ width: "100px", mr: 2 }}>
								Cancel
							</Button>
							{selectedFranchise && !selectedFranchise.is_approved === true && (
								<>
									{allowAccess(workforceApprovalRole, user) ? (
										<Button
											disabled={loading}
											onClick={() => handleSaveFunction(true)}
											variant="contained"
											sx={{ minWidth: "120px" }}>
											Save and Approve
										</Button>
									) : (
										<Button
											disabled={loading}
											onClick={handleSaveFunction}
											variant="contained"
											sx={{ minWidth: "120px" }}>
											Save
										</Button>
									)}
								</>
							)}
						</Box>
					</Box>
				</Stack>
			</Modal>
		</Stack>
	);
};

const FranchiseData = () => {
	const [selected, setSelected] = useState(0);

	const queryTypes = [
		{
			key: 0,
			name: "Cash Card Data",
			component: <CashCardCollection />,
		},
		{
			key: 1,
			name: "Workforce Data",
			component: <WorkForceData />,
		},
	];

	const handleQuerySelect = (key) => {
		setSelected(key);
	};
	return (
		<Box display="flex" height="100%">
			<Box height="100%" width={170} sx={{ borderRight: 1, borderColor: "divider" }}>
				{queryTypes &&
					queryTypes.map((row) => {
						return (
							<ListItemButton
								onClick={() => handleQuerySelect(row.key)}
								sx={{ padding: "2px", margin: "5px", borderRadius: "4px" }}
								selected={row.key === selected}
								key={row.key}>
								<ListItemText sx={{ padding: "2px 15px", fontSize: "13px" }} primary={row.name} />
							</ListItemButton>
						);
					})}
			</Box>

			{queryTypes &&
				queryTypes.map((row) => {
					return (
						<Box width={"80%"} height="100%" p={2} key={row.key} display={row.key === selected ? "block" : "none"}>
							{row.component}
						</Box>
					);
				})}
		</Box>
	);
};

export default FranchiseData;
