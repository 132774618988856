import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import StatusButtons from "../../reusableComp/statusButton.js";

import { useState } from "react";
import { GlobalState } from "context/GlobalContext";
import ComplianceCertificates from "../CommonComponents/ComplianceCertificates/CompalianceCertificates";
import {
	EditCompanyDetailsModalTypes,
	companyBasicDetailColumns,
	companyCommunicationAddressDetailColumns,
	companyRegistredAddressDetailColumns,
	complianceCertColumns,
	contactPersonColumns,
	fileTableColumns,
	financialDetailsColumns,
	industryTypeValues,
	labourTableColumns,
	msmeIndustryColumns,
	paymentModeOptions,
	paymentTermColumns,
} from "data/supplierPageData";
import DisplayInfo from "../CommonComponents/DisplayInfo/DisplayInfo";
import EditCompanyDetails from "./EditCompanyDetails/EditCompanyDetails";
import LabourDataTable from "./LabourDataTable/LabourDataTable";
import EditAddressDetails from "./EditAddressDetails/EditAddressDetails";
import DisplayAddressInfo from "../DisplayAddressInfo/DisplayAddressInfo";
import EditContactPersons from "../CommonComponents/EditContactPersons/EditContactPersons";
import EditFinancialDetails from "./EditFinancialDetails/EditFinancialDetails";
import DisplayTable from "../CommonComponents/DisplayTable/DisplayTable";
import AddFiles from "../CommonComponents/AddFiles/AddFiles";
import axios from "axios";
import { Dialog, DialogActions, DialogContent, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import VerifyFinancialDetailsModal from "./VerifyFinancialDetailsModal/VerifyFinancialDetailsModal.js";

const CompanyDetails = ({
	pageData,
	setPageData,
	isOnboarded,
	setIsOnboarded,
	foreignKey,
	disableEdit,
	handleReject,
	updateSupplierStore,
	handleEditSApprovalDb,
}) => {
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();

	// const [pageData, setPageData] = useState({
	// 	supplier_name: "",
	// 	company_name: "Company 1",
	// 	supplier_nature: "select",
	// 	company_type: "select",
	// 	no_of_factory: 0,
	// 	gst_no: "",
	// 	name_of_owner: "",
	// 	gender: "select",
	// 	position: "",
	// 	office_address: "",
	// 	office_state: "select",
	// 	office_city: "",
	// 	office_pin_code: "",
	// 	monthly_capacity: 0,
	// 	capacity_for_thor: 0,
	// 	no_of_labour: 0,
	// 	labour_roll: "select",
	// 	company_establishment_date: new Date(),

	// 	compliance_certificate_issuer: "",
	// 	compliance_certificate_issue_date: new Date(),
	// 	compliance_certificate_exp_date: new Date(),
	// 	website_link: "",
	// 	email: "",
	// 	mobile_no_primary: "",
	// 	mobile_no_secondary: "",
	// 	pan_no: "",
	// 	bank_name: "",
	// 	bank_ifsc_code: "",
	// 	bank_acc_no: "",
	// 	micr_code: "",
	// 	rtgs_code: "",
	// 	is_verified: false,
	// 	industry_type: "select",
	// });

	// const [pageData, setPageData] = useState(companyBasicDetailDummyValues);
	const [basicDetailsModal, setBasicDetailsModal] = useState(!isOnboarded);
	const [basicDetailsModalType, setBasicDetailsModalType] = useState(EditCompanyDetailsModalTypes.edit);

	const [addressDetailsModal, setAddressDetailsModal] = useState(false);
	const [financeDetailsModal, setFinanceDetailsModal] = useState(false);
	const [editContactModal, setEditContactModal] = useState(false);
	const [financeApproveModal, setFinanceApproveModal] = useState(false);
	const [selectedType, setSelectedType] = useState("");

	const [files, setFiles] = useState(
		pageData?.supplierFiles
			? pageData.supplierFiles
			: [
					// { name: "comp_cert", url: "https://google.com", type: "Compliance_Certificate" },
					// { name: "pan_card_photo", url: "https://google.com", type: "Pan_Card_Photo" },
					// { name: "blank_check_photo", url: "https://google.com", type: "Blank_Check_photocopy" },
					// { name: "office_gst_certificate", url: "https://google.com", type: "Office_gst_certificate" },
			  ]
	);
	const [contactPersons, setContactPersons] = useState(pageData.supplierContactPersons ? pageData.supplierContactPersons : []);
	const [complianceCerts, setComplianceCerts] = useState(
		pageData.supplierComplianceCertificates ? pageData.supplierComplianceCertificates : []
	);

	const date = new Date();

	const updateCompanyDetails = async (body, setModalOpen) => {
		// console.log(body);
		try {
			await axios.put(`/api/supplier/update/${pageData.id}`, body);
			// setPageData({ ...pageData, ...body });
			if (!isOnboarded) {
				handleFileAdd(body.files, true);
				setIsOnboarded(true);
			}
			setModalOpen(false);
			setSnackBarOpen(true);
			handleEditSApprovalDb(selectedType, { ...pageData, ...body });
			setSnackBar({
				...snackBar,
				message: "Saved",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Update Details",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	const updateFilename = (fileData, nameCol, col2) => {
		const Newname = `${fileData[nameCol]?.split(" ").join("_")}_${col2 ? fileData[col2] : ""}_${new Date().valueOf()}`;
		return new File([fileData.file], Newname, {
			type: fileData.file.type,
			lastModified: fileData.file.lastModified,
		});
	};
	const handleFileUploadGCP = async (fileData, isMultiple, nameCol, col2) => {
		// type is factory/company
		const formData = new FormData();
		// for setting folderPath
		formData.append("id", pageData.id);
		if (isMultiple) {
			for (let i = 0; i < fileData.length; i++) {
				formData.append("files", updateFilename(fileData[i], nameCol, col2));
			}
			return await axios
				.post("/api/supplier/file_upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return "Error in file Upload";
				});
		} else {
			formData.append("file", updateFilename(fileData, nameCol, col2));
			return await axios
				.post("/api/supplier/single_file_upload", formData, {
					headers: { "Content-Type": "multipart/form-data" },
				})
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return "Error in file Upload";
				});
		}
	};
	const uploadFileDataToSQL = async (fileData) => {
		const res = await axios.post("/api/supplier/file_data_to_sql", fileData);
		return res?.data?.result;
	};
	const uploadComplianceCertToSQL = async (fileData) => {
		const res = await axios.post("/api/supplier/compliance_cert_to_sql", fileData);
		return res?.data?.result;
	};

	const handleFileAdd = async (fileData, isMultiple) => {
		try {
			if (fileData) {
				const fileLinkData = await handleFileUploadGCP(
					fileData,
					isMultiple,
					fileTableColumns.name.key,
					fileTableColumns.type.key
				);
				let newFiles = [];
				if (isMultiple) {
					const updatedFiles = fileData.map((row, i) => {
						let obj = { ...row, url: fileLinkData[i] };
						obj[foreignKey] = pageData.id;
						delete obj["file"];
						return obj;
					});
					newFiles = updatedFiles;
				} else {
					const file = { ...fileData, url: fileLinkData };
					file[foreignKey] = pageData.id;
					delete file["file"];
					newFiles = [file];
				}
				const data = await uploadFileDataToSQL(newFiles);
				// console.log(data);
				if (data) {
					let arr = [];
					if (isMultiple) arr = [[...files, ...data]];
					else arr = [...files, data?.[0]];
					setFiles(arr);
					return true;
				}
			}
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Add File",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	const [selectedContact, setSelectedContact] = useState();

	const addCertificateFunction = async (fileData) => {
		try {
			if (fileData) {
				const fileLinkData = await handleFileUploadGCP(
					fileData,
					false,
					complianceCertColumns.compliance_certificate_issuer.key
				);
				let newFiles = [];
				const file = { ...fileData, url: fileLinkData };
				file[foreignKey] = pageData.id;
				delete file["file"];
				newFiles = [file];

				const data = await uploadComplianceCertToSQL(newFiles);
				// console.log(data);
				if (data?.[0]) {
					let arr = [...complianceCerts, data?.[0]];
					setComplianceCerts(arr);
					return true;
				} else throw new Error("Error in file Upload");
			}
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Add File",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};
	const addContactPerson = async (data) => {
		try {
			if (data) {
				data[foreignKey] = pageData.id;
				if (selectedContact) {
					const res = await axios.put("/api/supplier/update_contact", data);
					if (res) {
						let cp = contactPersons.map((row) => {
							if (data.id === row.id) return data;
							else return row;
						});
						setContactPersons(cp);
						setSnackBarOpen(true);
						handleEditSApprovalDb(selectedType, pageData);
						setSnackBar({
							...snackBar,
							message: "Updated",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						return true;
					}
				} else {
					const res = await axios.post("/api/supplier/create_contact", data);
					if (res) {
						setContactPersons([...contactPersons, res.data.result]);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Created",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						return true;
					}
				}
			}
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Add File",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};
	const handleContactClick = (contact) => {
		setSelectedContact(contact);
		setEditContactModal(true);
	};

	const handleFinanceApproveClick = () => {
		if (pageData.transaction_no && pageData?.transaction_no.length > 0) {
			updateSupplierStore("financial_details");
		} else setFinanceApproveModal(true);
		console.log(pageData, pageData.transaction_no);
	};

	const handleVerifyPaymentModeClick = async (transaction_no) => {
		try {
			await axios.put(`/api/supplier/update/${pageData.id}`, { transaction_no }).then((res) => {
				setPageData({ ...pageData, transaction_no });
				setFinanceApproveModal(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Payment Mode Verified ",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				updateSupplierStore("financial_details");
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Update Details",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	const handleRejectedButton = (type) => {
		const rejected = pageData?.supplierApproval?.filter((data) => data.type === type)[0];
		handleReject(rejected);
	};
	const logeduser = loginUser.role?.FINANCE_VIEWER ? true : false;

	const handleFileDeleteclick = async (row) => {
		if (!disableEdit) {
			setLoadingOpen(true);
			try {
				await axios.delete(`/api/supplier/delete_supplier_file/${row.id}`).then((res) => {
					setFiles(files.filter((cert) => row.id !== cert.id));
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Deleted",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoadingOpen(false);
				});
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable To Delete",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			}
		}
	};

	return (
		<Stack width="100%" spacing={4}>
			{/* company Details */}
			<Typography variant="h5" fontSize={28} textTransform="uppercase">
				Supplier / {pageData?.company_name}
			</Typography>

			{/* Basic Details */}
			<Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" rowGap={1}>
				<Box flexGrow={1}>
					<Typography variant="h6">Basic Details</Typography>
					<Divider orientation="horizontal" variant="fullWidth" />
				</Box>
				<StatusButtons
					handleReject={() => handleRejectedButton("basic_details")}
					handleApproval={() => updateSupplierStore("basic_details")}
					disableEdit={disableEdit}
					handleEdit={() => {
						setBasicDetailsModal(true);
						setSelectedType("basic_details");
					}}
					otherAccess={!logeduser}
					editButton={true}
					buttonName="Edit"
					approved={pageData?.supplierApproval.filter((data) => data.type === "basic_details")[0]?.approved}
					remark={pageData?.supplierApproval.filter((data) => data.type === "basic_details")[0]?.remark}
				/>
				<DisplayInfo
					labelData={
						pageData && pageData[companyBasicDetailColumns.industry_type.key] === industryTypeValues.msme
							? { ...companyBasicDetailColumns, ...msmeIndustryColumns }
							: companyBasicDetailColumns
					}
					values={pageData}
				/>

				<LabourDataTable data={pageData} setData={setPageData} editable={false} columns={labourTableColumns} />
			</Box>

			{/* payment Term */}
			<Box width="100%">
				<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Box width="98%">
						<Typography variant="h6">Office Use</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<DisplayInfo labelData={paymentTermColumns} values={pageData} />
				</Stack>
			</Box>

			{/* Address Details */}
			<Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
				<Stack direction={"row"} width={"100%"}>
					<Box flexGrow={1}>
						<Typography variant="h6">Address Details</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<StatusButtons
						handleReject={() => handleRejectedButton("address_details")}
						handleApproval={() => updateSupplierStore("address_details")}
						disableEdit={disableEdit}
						handleEdit={() => {
							setAddressDetailsModal(true);
							setSelectedType("address_details");
						}}
						otherAccess={!logeduser}
						editButton={true}
						buttonName="Edit"
						approved={pageData?.supplierApproval.filter((data) => data.type === "address_details")[0]?.approved}
						remark={pageData?.supplierApproval.filter((data) => data.type === "address_details")[0]?.remark}
					/>
				</Stack>
				{/* {!disableEdit && (
					<Button variant="contained" onClick={() => setAddressDetailsModal(true)}>
						Edit
					</Button>
				)} */}
				<Box width="50%">
					<DisplayAddressInfo labelData={companyRegistredAddressDetailColumns} values={pageData} />
				</Box>
				<Box width="50%">
					<DisplayAddressInfo labelData={companyCommunicationAddressDetailColumns} values={pageData} />
				</Box>
			</Box>
			{/* financial Details */}
			<Box width="100%">
				<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Box flexGrow={1}>
						<Typography variant="h6">Financial Details</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>

					<StatusButtons
						handleReject={() => handleRejectedButton("financial_details")}
						handleApproval={() => handleFinanceApproveClick()}
						disableEdit={disableEdit}
						editButton={true}
						handleEdit={() => {
							setFinanceDetailsModal(true);
							setSelectedType("financial_details");
						}}
						otherAccess={logeduser}
						buttonName="Edit"
						approved={pageData?.supplierApproval.filter((data) => data.type === "financial_details")[0]?.approved}
						remark={pageData?.supplierApproval.filter((data) => data.type === "financial_details")[0]?.remark}
					/>
					{/* {!disableEdit && (
						<Button variant="contained" onClick={() => setFinanceDetailsModal(true)}>
							Edit
						</Button>
					)} */}
					<DisplayInfo labelData={financialDetailsColumns} values={pageData} />
				</Stack>
			</Box>
			{/* files Section */}
			<Box width="100%">
				<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Stack direction={"row"} width={"100%"}>
						<Box flexGrow={1}>
							<Typography variant="h6">Files</Typography>
							<Divider orientation="horizontal" variant="fullWidth" />
						</Box>
						<StatusButtons
							handleReject={() => handleRejectedButton("files")}
							handleApproval={() => updateSupplierStore("files")}
							disableEdit={disableEdit}
							editButton={false}
							buttonName="Edit"
							handleEdit={() => {}}
							otherAccess={!logeduser}
							approved={pageData?.supplierApproval.filter((data) => data.type === "files")[0]?.approved}
							remark={pageData?.supplierApproval.filter((data) => data.type === "files")[0]?.remark}
						/>
					</Stack>
					<Box flexGrow={1} width="69%" maxHeight={310} minHeight={100} sx={{ overflowY: "scroll" }}>
						<DisplayTable
							rows={files}
							columns={fileTableColumns}
							downloadUrlColumn="url"
							deleteFun={handleFileDeleteclick}
							disableEdit={disableEdit}
						/>
					</Box>
					{!disableEdit && (
						<Box width="30%" sx={{ overflowY: "scroll" }}>
							<AddFiles
								fullWidth
								files={files}
								setFiles={setFiles}
								submitFunction={handleFileAdd}
								disableAdd={
									disableEdit || pageData?.supplierApproval.filter((data) => data.type === "files")[0]?.approved
								}
							/>
						</Box>
					)}
				</Stack>
			</Box>
			<Box pt={3}>
				<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Box flexGrow={1}>
						<Typography variant="h6">Compliance Certificates</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<StatusButtons
						handleReject={() => handleRejectedButton("compliance_certificates")}
						handleApproval={() => updateSupplierStore("compliance_certificates")}
						disableEdit={disableEdit}
						editButton={false}
						buttonName="Edit"
						handleEdit={() => {}}
						otherAccess={!logeduser}
						approved={pageData?.supplierApproval.filter((data) => data.type === "compliance_certificates")[0]?.approved}
						remark={pageData?.supplierApproval.filter((data) => data.type === "compliance_certificates")[0]?.remark}
					/>
				</Stack>
				<ComplianceCertificates
					complianceCerts={complianceCerts}
					disableEdit={disableEdit}
					setComplianceCerts={setComplianceCerts}
					addCertificateFunction={addCertificateFunction}
					disableAdd={
						disableEdit ||
						pageData?.supplierApproval.filter((data) => data.type === "compliance_certificates")[0]?.approved
					}
				/>
			</Box>
			<Box pt={3} pb={5} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
				<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Box flexGrow={1}>
						<Typography variant="h6">Contact Details</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<StatusButtons
						handleReject={() => handleRejectedButton("contact_details")}
						handleApproval={() => updateSupplierStore("contact_details")}
						disableEdit={disableEdit}
						handleEdit={() => {
							setEditContactModal(true);
							setSelectedType("contact_details");
						}}
						editButton={true}
						otherAccess={!logeduser}
						buttonName="Add"
						approved={pageData?.supplierApproval.filter((data) => data.type === "contact_details")[0]?.approved}
						remark={pageData?.supplierApproval.filter((data) => data.type === "contact_details")[0]?.remark}
					/>
				</Stack>
				<DisplayTable
					columns={contactPersonColumns}
					rows={contactPersons}
					disableEdit={
						disableEdit || pageData?.supplierApproval.filter((data) => data.type === "contact_details")[0]?.approved
							? true
							: false
					}
					onRowClick={
						disableEdit || pageData?.supplierApproval.filter((data) => data.type === "contact_details")[0]?.approved
							? () => {}
							: handleContactClick
					}
				/>{" "}
			</Box>

			{/* Models */}
			<EditCompanyDetails
				modalOpen={basicDetailsModal}
				setModalOpen={setBasicDetailsModal}
				basicDetails={pageData}
				setBasicDetails={setPageData}
				type={basicDetailsModalType}
				isOnboarded={isOnboarded}
				submitFunction={updateCompanyDetails}
			/>
			<EditAddressDetails
				modalOpen={addressDetailsModal}
				setModalOpen={setAddressDetailsModal}
				addressData={pageData}
				setAddressData={setPageData}
				submitFunction={updateCompanyDetails}
			/>
			<EditFinancialDetails
				modalOpen={financeDetailsModal}
				setModalOpen={setFinanceDetailsModal}
				data={pageData}
				setData={setPageData}
				submitFunction={updateCompanyDetails}
			/>
			<EditContactPersons
				modalOpen={editContactModal}
				setModalOpen={setEditContactModal}
				selected={selectedContact}
				setSelected={setSelectedContact}
				submitFunction={addContactPerson}
				key={editContactModal}
			/>

			<VerifyFinancialDetailsModal
				pageData={pageData}
				submitFunction={handleVerifyPaymentModeClick}
				modalOpen={financeApproveModal}
				setModalOpen={setFinanceApproveModal}
			/>
		</Stack>
	);
};

export default CompanyDetails;
