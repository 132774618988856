import { Box, Button, Dialog, DialogActions, InputLabel, MenuItem, Select, Stack, Table, TextField, Typography } from "@mui/material";
import { EditCompanyDetailsModalTypes, factoryBasicDetailColumns } from "data/supplierPageData";
import moment from "moment";
import { useState } from "react";

const EditFactoryDetails = ({ type, basicDetails, setBasicDetails, modalOpen, setModalOpen, submitFunction }) => {
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const [updatedDetails, setUpdatedDetails] = useState(basicDetails);
	const handleCloseModal = () => {
		setUpdatedDetails(basicDetails);
		setModalOpen(false);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		let obj = {};
		Object.keys(factoryBasicDetailColumns).forEach((col) => (obj[col] = updatedDetails[col]));
		if (typeof submitFunction === "function") submitFunction(obj, setModalOpen);
	};
	return (
		<Dialog fullWidth maxWidth="md" open={modalOpen} onClose={handleCloseModal} component="form" onSubmit={handleFormSubmit}>
			<Box width="98%" p={2} boxSizing="border-box">
				<Typography textAlign="center" variant="h6" p={2} width="100%">
					{type === EditCompanyDetailsModalTypes.edit ? "Factory Info" : "Enter Factory Details "}
				</Typography>
				<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Factory Name</InputLabel>
						<TextField
							size="small"
							type="text"
							value={updatedDetails?.name}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, name: e.target.value })}
							placeholder="Enter Name"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Factory Liscence Number</InputLabel>
						<TextField
							size="small"
							type="text"
							value={updatedDetails?.factory_liscence_no}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, factory_liscence_no: e.target.value })}
							placeholder="Enter Liscence No"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Factory Liscence Issue Date</InputLabel>
						<TextField
							size="small"
							type="date"
							value={moment(updatedDetails?.factory_liscence_issue_date).format("YYYY-MM-DD")}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, factory_liscence_issue_date: e.target.value })}
							placeholder="Enter Issue Date"
							required
						/>
					</Stack>

					<Stack sx={formStack}>
						<InputLabel>Factory Liscence Expiry Date</InputLabel>
						<TextField
							size="small"
							type="date"
							value={moment(updatedDetails?.factory_liscence_expiry_date).format("YYYY-MM-DD")}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, factory_liscence_expiry_date: e.target.value })}
							placeholder="Factory Liscence Expiry Date"
							required
						/>
					</Stack>
					<Stack sx={formStack}>
						<InputLabel>Factory GST NO:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={updatedDetails?.factory_gst_no}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, factory_gst_no: e.target.value })}
							placeholder="Enter GSt No"
							required
						/>
					</Stack>
					<Stack sx={formStack}>
						<InputLabel>Location Link (Google Maps):</InputLabel>
						<TextField
							size="small"
							type="text"
							value={updatedDetails?.gmap_location_link}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, gmap_location_link: e.target.value })}
							placeholder="Enter Location Link"
							required
						/>
					</Stack>

					{/* <Stack className="vendor-input">
				<InputLabel>Factory Gst Certificate</InputLabel>
				<TextField
					size="small"
					type="file"
					accept=".pdf, .jpeg, .jpg, .png"
					required
					onChange={(event) => handleFileChange(event, "gst_cert")}
				/>
			</Stack>
			<Stack className="vendor-input">
				<InputLabel>Factory Liscence Certificate</InputLabel>
				<TextField
					size="small"
					type="file"
					accept=".pdf, .jpeg, .jpg, .png"
					required
					onChange={(event) => handleFileChange(event, "factory_liscence_cert")}
				/>
			</Stack> */}
				</Stack>
			</Box>
			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					{type === EditCompanyDetailsModalTypes.edit ? "Save" : "Create"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditFactoryDetails;
