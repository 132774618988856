import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";

const DisplayInfo = ({ labelData, values }) => {
	return (
		<Box width="100%" display="flex" flexWrap="wrap" columnGap="5%" rowGap={2}>
			{Object.keys(labelData).map((colName) => {
				const isDate = labelData[colName]?.isDate;
				return (
					<Stack width="30%" spacing={0} key={colName} height={50} overflow="hidden">
						<Typography variant="subtitle2" color={grey[600]}>
							{labelData[colName]?.name}
						</Typography>
						<Tooltip
							title={isDate ? moment(values[colName]).format("DD/MM/YYYY") : values[colName]}
							placement="bottom-start">
							<Typography noWrap variant="h6" sx={{ overflow: "hidden", width: "100%", textOverflow: "ellipsis" }}>
								{isDate ? moment(values[colName]).format("DD/MM/YYYY") : values[colName]}
							</Typography>
						</Tooltip>

					</Stack>
				);
			})}
		</Box>
	);
};

export default DisplayInfo;
