export const trimsCategories = [
	{ name: "BUTTON", key: "BUTTON" },
	{ name: "THREAD", key: "THREAD" },
	{ name: "TAG", key: "TAG" },
	{ name: "CARTON BOX", key: "CARTON BOX" },
	{ name: "CARTON TAPE", key: "CARTON TAPE" },
	{ name: "COLLAR BONE", key: "COLLAR BONE" },
	{ name: "LABEL", key: "LABEL" },
	{ name: "HOLOGRAM STICKER", key: "HOLOGRAM STICKER" },
	{ name: "INTERLINING", key: "INTERLINING" },
	{ name: "CLIP", key: "CLIP" },
	{ name: "HANG-TAG", key: "HANG-TAG" },
	{ name: "MAIN LABEL", key: "MAIN LABEL" },
	{ name: "PAPER NECKBAND", key: "PAPER NECKBAND" },
	{ name: "PLASTIC NECKBAND", key: "PLASTIC NECKBAND" },
	{ name: "POLY WRAP", key: "POLY WRAP" },
];
