import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useEffect, useState } from "react";
import { GlobalState } from "context/GlobalContext";
import ComplianceCertificates from "../CommonComponents/ComplianceCertificates/CompalianceCertificates";
import {
	EditCompanyDetailsModalTypes,
	complianceCertColumns,
	contactPersonColumns,
	factoryAddressDetailColumns,
	factoryBasicDetailColumns,
	factoryUrlParamName,
	fileTableColumns,
	supplierDrawerElements,
	supplierUrlParamName,
} from "data/supplierPageData";
import DisplayInfo from "../CommonComponents/DisplayInfo/DisplayInfo";
import EditAddressDetails from "./EditAddressDetails/EditAddressDetails";
import DisplayAddressInfo from "../DisplayAddressInfo/DisplayAddressInfo";
import EditContactPersons from "../CommonComponents/EditContactPersons/EditContactPersons";
// import EditFinancialDetails from "./EditFinancialDetails/EditFinancialDetails";
import DisplayTable from "../CommonComponents/DisplayTable/DisplayTable";
import AddFiles from "../CommonComponents/AddFiles/AddFiles";
import axios from "axios";
import EditFactoryDetails from "./EditFactoryDetails/EditFactoryDetails";
import { useSearchParams } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import StatusButtons from "components/SupplierPage/reusableComp/statusButton";

const FactoryDetails = ({
	foreignKey,
	companyData,
	pageData,
	setPageData,
	setTab,
	disableEdit,
	handleReject,
	updateSupplierStore,
	handleEditFApprovalDb,
}) => {
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();

	const [basicDetailsModal, setBasicDetailsModal] = useState(false);
	const [basicDetailsModalType, setBasicDetailsModalType] = useState(EditCompanyDetailsModalTypes.edit);

	const [addressDetailsModal, setAddressDetailsModal] = useState(false);
	const [financeDetailsModal, setFinanceDetailsModal] = useState(false);
	const [editContactModal, setEditContactModal] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedType, setSelectedType] = useState("");
	const fetch = async (id) => {
		try {
			const factory = await axios.get(`/api/supplier/factory?id=${id}`);
			setPageData({ ...factory.data.result });
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Get factory details",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	useEffect(() => {
		const id = searchParams.get(factoryUrlParamName);
		const tab = searchParams.get(supplierUrlParamName);
		if (id && !pageData) {
			fetch(id);
		}
		if (!id && tab === supplierDrawerElements.factory.key) setTab(supplierDrawerElements.all_factories.key);
	}, [searchParams]);

	const [files, setFiles] = useState(
		pageData && pageData.factoryFiles
			? pageData.factoryFiles
			: [
					// { name: "comp_cert", url: "https://google.com", type: "Compliance_Certificate" },
					// { name: "pan_card_photo", url: "https://google.com", type: "Pan_Card_Photo" },
					// { name: "blank_check_photo", url: "https://google.com", type: "Blank_Check_photocopy" },
					// { name: "office_gst_certificate", url: "https://google.com", type: "Office_gst_certificate" },
			  ]
	);
	const [contactPersons, setContactPersons] = useState(
		pageData && pageData.factoryContactPersons ? pageData.factoryContactPersons : []
	);
	const [complianceCerts, setComplianceCerts] = useState(
		pageData && pageData.factoryComplianceCertificates ? pageData.factoryComplianceCertificates : []
	);

	const date = new Date();

	const updateCompanyDetails = async (body, setModalOpen) => {
		// console.log(body);
		try {
			await axios.put(`/api/supplier/update_factory/${pageData.id}`, body);
			setPageData({ ...pageData, ...body });
			setModalOpen(false);
			setSnackBarOpen(true);
			handleEditFApprovalDb(selectedType);
			setSnackBar({
				...snackBar,
				message: "Saved",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Update Details",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	const updateFilename = (fileData, nameCol, col2) => {
		const Newname = `${fileData[nameCol]?.split(" ").join("_")}_${col2 ? fileData[col2] : ""}_${new Date().valueOf()}`;
		return new File([fileData.file], Newname, {
			type: fileData.file.type,
			lastModified: fileData.file.lastModified,
		});
	};
	const handleFileUploadGCP = async (fileData, isMultiple, nameCol, col2) => {
		// type is factory/company
		const formData = new FormData();
		// for setting folderPath
		formData.append("id", pageData.id);
		if (isMultiple) {
			for (let i = 0; i < fileData.length; i++) {
				formData.append("files", updateFilename(fileData[i], nameCol, col2));
			}
			return await axios
				.post("/api/supplier/file_upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return "Error in file Upload";
				});
		} else {
			formData.append("file", updateFilename(fileData, nameCol, col2));
			return await axios
				.post("/api/supplier/single_file_upload", formData, {
					headers: { "Content-Type": "multipart/form-data" },
				})
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return "Error in file Upload";
				});
		}
	};
	const uploadFileDataToSQL = async (fileData) => {
		const res = await axios.post("/api/supplier/file_data_to_sql", fileData);
		return res?.data?.result;
	};
	const uploadComplianceCertToSQL = async (fileData) => {
		const res = await axios.post("/api/supplier/compliance_cert_to_sql", fileData);
		return res?.data?.result;
	};

	const handleFileAdd = async (fileData, isMultiple) => {
		try {
			if (fileData) {
				const fileLinkData = await handleFileUploadGCP(
					fileData,
					isMultiple,
					fileTableColumns.name.key,
					fileTableColumns.type.key
				);
				let newFiles = [];
				if (isMultiple) {
					const updatedFiles = fileData.map((row, i) => {
						let obj = { ...row, url: fileLinkData[i] };
						obj[foreignKey] = pageData.id;
						delete obj["file"];
						return obj;
					});
					newFiles = updatedFiles;
				} else {
					const file = { ...fileData, url: fileLinkData };
					file[foreignKey] = pageData.id;
					delete file["file"];
					newFiles = [file];
				}
				const data = await uploadFileDataToSQL(newFiles);
				// console.log(data);

				if (data) {
					let arr = [];
					if (isMultiple) arr = [[...files, ...data]];
					else arr = [...files, data?.[0]];
					setFiles(arr);
					return true;
				}
			}
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Add File",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	const [selectedContact, setSelectedContact] = useState();

	const addCertificateFunction = async (fileData) => {
		try {
			if (fileData) {
				const fileLinkData = await handleFileUploadGCP(
					fileData,
					false,
					complianceCertColumns.compliance_certificate_issuer.key
				);
				let newFiles = [];
				const file = { ...fileData, url: fileLinkData };
				file[foreignKey] = pageData.id;
				delete file["file"];
				newFiles = [file];

				const data = await uploadComplianceCertToSQL(newFiles);
				// console.log(data);

				if (data?.[0]) {
					let arr = [...complianceCerts, data?.[0]];
					setComplianceCerts(arr);
					return true;
				} else throw new Error("Error in file Upload");
			}
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Add File",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};
	const addContactPerson = async (data) => {
		try {
			if (data) {
				data[foreignKey] = pageData.id;
				if (selectedContact) {
					const result = await axios.put("/api/supplier/update_contact", data);
					if (result) {
						setContactPersons(
							contactPersons.map((row) => {
								if (data.id === row.id) return data;
								else return row;
							})
						);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Updated",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						return true;
					}
				} else {
					const result = await axios.post("/api/supplier/create_contact", data);
					if (result) {
						setContactPersons([...contactPersons, result.data.result]);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Created",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						return true;
					}
				}
			}
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Add File",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};
	const logeduser = loginUser.role?.FINANCE_VIEWER ? true : false;
	const handleContactClick = (contact) => {
		setSelectedContact(contact);
		setEditContactModal(true);
	};
	const handleRejectedFactoryButton = (type) => {
		const rejected = pageData?.factoryApproval?.filter((data) => data.type === type)[0];
		handleReject(rejected);
	};
	const handleFileDeleteclick = async (row) => {
		if (!disableEdit) {
			setLoadingOpen(true);
			try {
				await axios.delete(`/api/supplier/delete_supplier_file/${row.id}`).then((res) => {
					setFiles(files.filter((cert) => row.id !== cert.id));
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Deleted",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoadingOpen(false);
				});
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable To Delete",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			}
		}
	};
	if (pageData)
		return (
			<Stack width="100%" spacing={4}>
				{/* company Details */}

				<Breadcrumbs aria-label="breadcrumb">
					<Typography variant="h5" fontSize={22} textTransform="uppercase">
						Supplier
					</Typography>
					<Typography
						variant="h5"
						fontSize={22}
						textTransform="uppercase"
						sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
						onClick={() => {
							setTab(supplierDrawerElements.company_info.key);
							setSearchParams((searchParams) => {
								searchParams.set(supplierUrlParamName, supplierDrawerElements.company_info.key);
								return searchParams;
							});
						}}>
						{companyData?.company_name}
					</Typography>
					<Typography variant="h5" fontSize={22} textTransform="uppercase">
						Factory
					</Typography>
					<Typography color="text.primary" fontSize={22} textTransform="uppercase">
						{pageData?.name}
					</Typography>
				</Breadcrumbs>

				{/* Basic Details */}
				<Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" rowGap={1}>
					<Box flexGrow={1}>
						<Typography variant="h6">Basic Details</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Stack direction="row">
						<StatusButtons
							handleReject={() => handleRejectedFactoryButton("basic_details")}
							handleApproval={() => updateSupplierStore("basic_details")}
							disableEdit={disableEdit}
							handleEdit={() => {
								setBasicDetailsModal(true);
								setSelectedType("basic_details");
							}}
							otherAccess={!logeduser}
							editButton={true}
							buttonName="Edit"
							approved={pageData?.factoryApproval.filter((data) => data.type === "basic_details")[0]?.approved}
							remark={pageData?.factoryApproval.filter((data) => data.type === "basic_details")[0]?.remark}
						/>
					</Stack>
					<DisplayInfo labelData={factoryBasicDetailColumns} values={pageData} />
				</Box>

				{/* Address Details */}
				<Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap">
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Box flexGrow={1}>
							<Typography variant="h6">Address Details</Typography>
							<Divider orientation="horizontal" variant="fullWidth" />
						</Box>
						<Stack direction="row">
							<StatusButtons
								handleReject={() => handleRejectedFactoryButton("address_details")}
								handleApproval={() => updateSupplierStore("address_details")}
								disableEdit={disableEdit}
								handleEdit={() => {
									setAddressDetailsModal(true);
									setSelectedType("address_details");
								}}
								editButton={true}
								otherAccess={!logeduser}
								buttonName="Edit"
								approved={pageData?.factoryApproval.filter((data) => data.type === "address_details")[0]?.approved}
								remark={pageData?.factoryApproval.filter((data) => data.type === "address_details")[0]?.remark}
							/>
						</Stack>
					</Stack>
					<Box width="60%">
						<DisplayAddressInfo labelData={factoryAddressDetailColumns} values={pageData} />
					</Box>
				</Box>
				{/* <Box width="100%">
				<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Box width="90%">
						<Typography variant="h6">Financial Details</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Button variant="contained" onClick={() => setFinanceDetailsModal(true)}>
						Edit
					</Button>
					<DisplayInfo labelData={financialDetailsColumns} values={pageData} />
				</Stack>
			</Box> */}
				<Box width="100%">
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Stack direction={"row"} width={"100%"} rowGap={1}>
							<Box flexGrow={1}>
								<Typography variant="h6">Files</Typography>
								<Divider orientation="horizontal" variant="fullWidth" />
							</Box>
							<Stack direction="row">
								<StatusButtons
									handleReject={() => handleRejectedFactoryButton("files")}
									handleApproval={() => updateSupplierStore("files")}
									disableEdit={disableEdit}
									handleEdit={() => {}}
									editButton={false}
									buttonName="Edit"
									otherAccess={!logeduser}
									approved={pageData?.factoryApproval.filter((data) => data.type === "files")[0]?.approved}
									remark={pageData?.factoryApproval.filter((data) => data.type === "files")[0]?.remark}
								/>
							</Stack>
						</Stack>
						<Box width="69%" flexGrow={1} maxHeight={310} minHeight={100} sx={{ overflowY: "scroll" }}>
							<DisplayTable
								rows={files}
								columns={fileTableColumns}
								downloadUrlColumn="url"
								deleteFun={handleFileDeleteclick}
								disableEdit={disableEdit}
							/>
						</Box>
						{!disableEdit && (
							<Box width="30%" sx={{ overflowY: "scroll" }}>
								<AddFiles
									fullWidth
									files={files}
									setFiles={setFiles}
									submitFunction={handleFileAdd}
									disableAdd={
										disableEdit || pageData?.factoryApproval.filter((data) => data.type === "files")[0]?.approved
									}
								/>
							</Box>
						)}
					</Stack>
				</Box>
				<Box pt={3}>
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Box flexGrow={1}>
							<Typography variant="h6">Compliance Certificates</Typography>
							<Divider orientation="horizontal" variant="fullWidth" />
						</Box>
						<Stack direction="row">
							<StatusButtons
								handleReject={() => handleRejectedFactoryButton("compliance_certificates")}
								handleApproval={() => updateSupplierStore("compliance_certificates")}
								disableEdit={disableEdit}
								handleEdit={() => {}}
								editButton={false}
								otherAccess={!logeduser}
								buttonName="Edit"
								approved={
									pageData?.factoryApproval.filter((data) => data.type === "compliance_certificates")[0]?.approved
								}
								remark={pageData?.factoryApproval.filter((data) => data.type === "compliance_certificates")[0]?.remark}
							/>
						</Stack>
					</Stack>
					<ComplianceCertificates
						complianceCerts={complianceCerts}
						setComplianceCerts={setComplianceCerts}
						addCertificateFunction={addCertificateFunction}
						disableAdd={
							disableEdit ||
							pageData?.factoryApproval.filter((data) => data.type === "compliance_certificates")[0]?.approved
						}
						disableEdit={disableEdit}
					/>
				</Box>
				<Box pt={3} pb={5} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Box flexGrow={1}>
							<Typography variant="h6">Contact Details</Typography>
							<Divider orientation="horizontal" variant="fullWidth" />
						</Box>
						<Stack direction="row">
							<StatusButtons
								handleReject={() => handleRejectedFactoryButton("contact_details")}
								handleApproval={() => updateSupplierStore("contact_details")}
								disableEdit={disableEdit}
								handleEdit={() => {
									setEditContactModal(true);
									setSelectedType("contact_details");
								}}
								editButton={true}
								otherAccess={!logeduser}
								buttonName="Add"
								approved={pageData?.factoryApproval.filter((data) => data.type === "contact_details")[0]?.approved}
								remark={pageData?.factoryApproval.filter((data) => data.type === "contact_details")[0]?.remark}
							/>
						</Stack>
					</Stack>
					<DisplayTable
						columns={contactPersonColumns}
						rows={contactPersons}
						disableEdit={
							disableEdit || pageData?.factoryApproval.filter((data) => data.type === "contact_details")[0]?.approved
								? true
								: false
						}
						onRowClick={
							disableEdit || pageData?.factoryApproval.filter((data) => data.type === "contact_details")[0]?.approved
								? () => {}
								: handleContactClick
						}
					/>
				</Box>

				{/* Models */}
				<EditFactoryDetails
					modalOpen={basicDetailsModal}
					setModalOpen={setBasicDetailsModal}
					basicDetails={pageData}
					setBasicDetails={setPageData}
					type={EditCompanyDetailsModalTypes.edit}
					submitFunction={updateCompanyDetails}
				/>
				<EditAddressDetails
					modalOpen={addressDetailsModal}
					setModalOpen={setAddressDetailsModal}
					addressData={pageData}
					setAddressData={setPageData}
					submitFunction={updateCompanyDetails}
					companyData={companyData}
				/>
				{/* <EditFinancialDetails
				modalOpen={financeDetailsModal}
				setModalOpen={setFinanceDetailsModal}
				data={pageData}
				setData={setPageData}
				submitFunction={updateCompanyDetails}
			/> */}
				<EditContactPersons
					modalOpen={editContactModal}
					setModalOpen={setEditContactModal}
					selected={selectedContact}
					setSelected={setSelectedContact}
					submitFunction={addContactPerson}
					key={editContactModal}
				/>
			</Stack>
		);
};

export default FactoryDetails;
